import React, { useEffect, useRef, useState, useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  Box,
  Button,
  Typography,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  isSessionValid,
  getFromLocalStorage,
  logout,
  navigateToEnterprise,
  getCurrentUserToken,
  addDataToKeycloakRedirectURI,
} from "../../helpers/helper";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchBar from "../../Components/shared/searchbar";
import ApiCalls from "../../api/customAxios";
import { ReactComponent as MessageIcon } from "../../assets/images/message-icon-new.svg";
import { checkIsImage, getValidFullName, setPlatformTheme } from "../../helpers/helper-data";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon-new.svg";
import { ReactComponent as SupportIcon } from "../../assets/images/Support.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings-icon-header.svg";
import { ReactComponent as UserCircle } from "../../assets/images/user-circle-icon.svg";
import NotificationButton from "../../pages/Private/Notification/NotificationButton";
import Loader from "../../pages/Loader";
import { formatDate, isMinutesOrHours } from "../../helpers/helper-data";
import { Auth } from "aws-amplify";
import { getCandidateRecode } from "../../redux/Profile/profile";
import { useDispatch, useSelector } from "react-redux";
import AnalyticsSideBar from "../../pages/Private/Reports/AnalyticsSideBar";
import FeedBackModal from "../../pages/Private/FeedBackModal";
import axios from "axios";
import healthcareLogo from "../../assets/images/direct-healthcare-logo.svg";
import NeuroDiversityLogo from "../../assets/images/NeuroDiversityLogo.svg";
import KeycloakService from "../../keycloak";
import Modal from "@mui/material/Modal";
import Constants from "../../helpers/constants";
import { ReactComponent as ReferCandidate } from "../../assets/images/refer-candidate-icon.svg";

export default function NavbarNew() {
  const appOrigin = useSelector((state) => state.location.origin);
  const navigate = useNavigate();
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const URLPath = location.pathname;
  const candidateInfo = useSelector((state) => state.profile.candidate);
  const dispatch = useDispatch();
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const params = new URLSearchParams(location.search);
  const [getUserToken, setUserToken] = useState(null);
  const [searchedSkills, setSearchedSkills] = useState("");
  const [searchedLocation, setSearchedLocation] = useState("");
  const [globalSearchClick, setGlobalSearchClick] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [openfeedback, setOpenFeedback] = useState(false);
  const [openValidUser, setOpenValidUser] = React.useState(false);
  const [validUserContent, setValidUserContent] = useState("");
  const { id } = useParams();
  const CandidateMenu = [
    {
      name: "Home",
      url: "/home",
    },
    {
      name: "Find Jobs",
      url: "/candidate/find-jobs",
    },
    {
      name: "My Jobs",
      url: "/candidate/my-jobs",
    },
    {
      name: "Reports",
      url: "Reports",
    },
    {
      name: "Assessments",
      url: "/candidate/add-checklist",
    },
  ];
  const isAuthenticated = KeycloakService.isLoggedIn();
  const [show, setShow] = useState(false);
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const [anchorE3, setAnchorE3] = useState(null);
  const open3 = Boolean(anchorE3);

  const handleClose3 = () => {
    setAnchorE3(null);
  };

  const viewAllNotification = () => {
    setNotificationListDrawer(true);
    setAnchorE2(null);
    // navigate("/candidate/notifications");
    handleClose3();
  };

  const getDsaasTheme = () => {
    let result = '';
    if (localStorage.getItem('theme_obj')) {
      const theme = JSON.parse(localStorage.getItem('theme_obj'));
      theme.logo = localStorage.getItem('domainNavBarLogoSignedUrl');

      result = btoa(JSON.stringify(theme));
    }

    return result;
  };
  const goToSignin = () => {
    if (URLPath.includes("job-list") && params.get("paramsKey")) {
      KeycloakService.doLogin({
        redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/find-jobs?paramsKey=${params.get("paramsKey")}`),
      });
    } else if (params?.get("utm_source")) {
      KeycloakService.doLogin({
        redirectUri: addDataToKeycloakRedirectURI(`${window.location.href}`),
      });
    } else if (id) {
      KeycloakService.doLogin({
        redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/job-details/${id}`),
      });
    } else {
    KeycloakService.doLogin({
      redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/dashboard`),
    });
    }
  };
  const goToSignup = () => {
    if (id) {
      localStorage.setItem("jobPage", `${id}`);
    }
    KeycloakService.doRegisteration({
      redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/create-profile`),
    });
  };

  const signOut = async () => {
    await logout();
    // navigate("/signin");
    process.env.REACT_APP_CHAT_BOT === "haptik" ? HaptikSDK.logout() : "";
    window.location.href = "/signin";
  };

  const [isloggedIn, setLoggedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };
  const [domainNavBarLogoSignedUrl, setDomainNavBarLogoSignedUrl] = useState(null);
  const [exclusiveLogoSignedUrl, setExclusiveLogoSignedUrl] = useState(null);
  const [careerPortalFavIcon, setCareerPortalFavIcon] = useState(null);
  useEffect(() => {
    let exclusiveReferral = params.get("referralDetails") ? JSON.parse(atob(params.get("referralDetails"))) : null;//referred candidate should be exclusive
    if (params.get("paramsKey") || localStorage.getItem("domain") || exclusiveReferral?.validDomainName) {
      setFirstLoad(true);
      try {
      let domain = params.get("paramsKey") ? JSON.parse(atob(params.get("paramsKey")))?.validDomainNames : exclusiveReferral?.validDomainName ? exclusiveReferral?.validDomainName : localStorage.getItem("domain");
      localStorage.setItem("domain", domain);
      const publicEnterpriseDetailsUrl = `${process.env.REACT_APP_API_URL.replace(
        "api/v1/",
        "public/api/v1/"
      )}candidates/enterprise/details/${domain}`;
      ApiCalls.get(publicEnterpriseDetailsUrl)
      .then((res) => {
        localStorage.setItem("sourceEnterpriseId", res.enterpriseId);
        localStorage.setItem("domainEnterpriseId", res.enterpriseId);
        if (localStorage.getItem("pool_id")) {
          let pool = { "pool_id": localStorage.getItem("pool_id"), "id": res.enterpriseId, "ename": res.establishmentName };
          localStorage.setItem("poolFromPortal", JSON.stringify(pool));
          localStorage.removeItem("pool_id");
        }
        if (!(candidateInfo?.sourceEnterpriseId && candidateInfo?.talentPool === "Z" && res.sourceEnterpriseId !== candidateInfo?.sourceEnterpriseId)) {
        res.exclusiveCandidateTheme ? setPlatformTheme(res.exclusiveCandidateTheme, appOrigin) : setPlatformTheme(res.theme, appOrigin);
        }
        if (res.exclusiveCandidateLogoFileSignedUrl) {
          setDomainNavBarLogoSignedUrl(res.exclusiveCandidateLogoFileSignedUrl);
          localStorage.setItem("domainNavBarLogoSignedUrl", res.exclusiveCandidateLogoFileSignedUrl);
        } else if (res.navBarLogoSignedUrl)  {
          setDomainNavBarLogoSignedUrl(res.navBarLogoSignedUrl);
          localStorage.setItem("domainNavBarLogoSignedUrl", res.navBarLogoSignedUrl);
        }
        if (res.careerPortalFavIcon) {
          localStorage.setItem("careerPortalFavIcon", res.careerPortalFavIcon);
          setCareerPortalFavIcon(res.careerPortalFavIcon);
        }
        setFirstLoad(false);
      });
      } catch (err) {
        setFirstLoad(false);
      }
    } else if (params.get("utm_source") == null && params.get("isFromShareJob") == null) {
      setFirstLoad(false);
    }
  }, [params.get("paramsKey"), localStorage.getItem("domain"), params.get("referralDetails"), params?.get("isFromShareJob")]);
  useEffect(() => {
    if (careerPortalFavIcon || localStorage.getItem("careerPortalFavIcon")) {
      if (window.location.pathname != "/") {
      document
        .querySelector("link[title=icon]")
        .setAttribute("href", careerPortalFavIcon ? careerPortalFavIcon : localStorage.getItem("careerPortalFavIcon"));
      }
    } else if (appOrigin === "H") {
      document.querySelector("link[title=icon]").setAttribute("href", "/healthcare-favicon.png");
    } else if (appOrigin === "N") {
      document.querySelector("link[title=icon]").setAttribute("href", "/neuro-favicon.png");
    } else if (params.get("utm_source") == null && params.get("isFromShareJob") == null) {
      document.querySelector("link[title=icon]").setAttribute("href", "/favicon.png");
    }
  }, [careerPortalFavIcon, localStorage.getItem("careerPortalFavIcon")]);
  const signOutUser = async () => {
    setIsLoading(true);
    await logout();
    setOpenValidUser(false);
    setIsLoading(false);
  };
  const convertToValidUser = () => {
    setIsLoading(true);
    let data = {
      neuroClassification: process.env.REACT_APP_ORIGIN === "neurodiversify" ? "ND" : "NT",
    };
    if (candidateInfo?.id) {
      data.id = candidateInfo.id;
    }
    ApiCalls.put("candidates/", data).then((response) => {
      setOpenValidUser(false);
      dispatch(getCandidateRecode(response));
      if (location.pathname.split("/")[2] !== "public") {
        if (
          localStorage.getItem("jobId") &&
          localStorage.getItem("registered") &&
          localStorage.getItem("first_step") &&
          localStorage.getItem("second_step")
        ) {
        } else {
          getSkills();
        }
      };
      setIsLoading(false);
    }).catch((err) => {
      danger("Something went wrong");
      setIsLoading(false);
    });
  };
  useEffect(() => {
    let isUserAuthenticated = getCurrentUserToken();
    isUserAuthenticated.then(async (res) => {
      await setUserToken(res);
    });
  }, []);
  React.useEffect(() => {
    isSessionValid().then(async (res) => {
      if (res === true) {
        await axios
          .get("https://ipapi.co/json/")
          .then(async (response) => {
            await ApiCalls.put(
              `/candidates/update/region/${response?.data?.country_name}`
            )
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
        if (
          localStorage.getItem("jobId") &&
          localStorage.getItem("registered") &&
          localStorage.getItem("first_step") &&
          localStorage.getItem("second_step")
        ) {
          storeAlldeatils();
        } else {
          getProfileInfo();
        }
      }
      // setLoggedIn(res);
      setIsLoading(false);
    });
  }, []);
  const [anchorEl5, setAnchorEl5] = useState(null);
  const menuRef = useRef(null);

  const handleClick5 = (event) => {
    setShow(!show);
    setAnchorEl5(event.currentTarget);
  };

  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // handleClose();
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const [showTopSearchFilters, setShowTopSearchFilters] = useState(false);
  const myRef = useRef(null);

  function settingScreen() {
    navigate("/candidate/settings");
    setAnchorE2(null);
  }

  function goToEditProfile() {
    navigate("/candidate/edit-profile");
    setAnchorE2(null);
  }

  const [anchorE4, setAnchorE4] = useState(null);
  const open4 = Boolean(anchorE4);
  const handleClose4 = () => {
    setAnchorE4(null);
  };
  const handleClick4 = (event) => {
    setAnchorE4(event.currentTarget);
  };

  function goToChecklist() {
    navigate("/candidate/add-checklist");
  }
  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setShowTopSearchFilters(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const switchToEnterprise = () => {
    window.open(process.env.REACT_APP_ENTERPRISE_BASE_URL);
  };
  const handleOpenFeedBack = () => {
    setOpenFeedback(true);
  };
  const handleModalClose = () => {
    setOpenFeedback(false);
    setAnchorE2(null);
  };
  function goToMyReferrals() {
    setAnchorE2(null);
    navigate("/candidate/my-referrals");
  }
  function goToMessages() {
    navigate("/candidate/message-list");
    setAnchorE2(null);
  }
  const getEnterpriseInfo = async (data) => {
    await ApiCalls.get(
      `${process.env.REACT_APP_API_URL.replace(
        "api/v1/",
        "public/api/v1/"
      )}enterprises/${data.sourceEnterpriseId}/details`
    )
      .then((response) => {
        if (data.talentPool === "Z") {
          localStorage.setItem(
            "enterprisenavBarLogo",
            response?.navBarLogoSignedUrl
          );
          if (
            response?.navBarLogoSignedUrl == null ||
            localStorage.getItem("enterprisenavBarLogo") == null ||
            localStorage.getItem("enterprisenavBarLogo") == ""
          ) {
          } else {
            setDomainNavBarLogoSignedUrl(response?.navBarLogoSignedUrl);
          }
          if (response?.careerPortalFavicon) {
            localStorage.setItem(
              "careerPortalFavIcon",
              response?.careerPortalFavicon
            );
            setCareerPortalFavIcon(response?.careerPortalFavicon);
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const getProfileInfo = () => {
    // setLoggedIn(true);
    setIsLoading(true);

    ApiCalls.get("candidates/").then(
      (response) => {
        let validCandidate = process.env.REACT_APP_ORIGIN === "neurodiversify" ? "ND" : "NT";
        if (response.neuroClassification != validCandidate) {
          setOpenValidUser(true);
          setValidUserContent(response.neuroClassification == "ND" ? "This platform has been designed just for neurotypical candidates. Are you sure you want to log in?" :
            "This platform has been designed just for neurodistinct candidates. Are you sure you want to log in?");
          return false;
        }
        dispatch(getCandidateRecode(response));
        if (
          response.sourceEnterpriseId !== null &&
          (response.unbindRequestStatus === null ||
            response.unbindRequestStatus === "P" ||
            response.unbindRequestStatus === "R" ||
            response.talentPool === "Z")
        ) {
          response.exclusiveCandidateTheme ? setPlatformTheme(response.exclusiveCandidateTheme, appOrigin) : setPlatformTheme(response.enterpriseTheme, appOrigin);
          response.exclusiveCandidateLogoFileSignedUrl ?
          (setDomainNavBarLogoSignedUrl(response.exclusiveCandidateLogoFileSignedUrl),
           localStorage.setItem("domainNavBarLogoSignedUrl", response.exclusiveCandidateLogoFileSignedUrl))
           :
          (setDomainNavBarLogoSignedUrl(response.navBarLogoSignedUrl),
          localStorage.setItem("domainNavBarLogoSignedUrl", response.navBarLogoSignedUrl));
          getEnterpriseInfo(response);
        }
        if (location.pathname.split("/")[2] !== "public") {
          if (
            localStorage.getItem("jobId") &&
            localStorage.getItem("registered") &&
            localStorage.getItem("first_step") &&
            localStorage.getItem("second_step")
          ) {
          } else {
            getSkills();
          }
        };
        setLoggedIn(true);
      }).catch(
      (error) => {
        setLoggedIn(false);
        setIsLoading(false);
      }
    );
  };
  const getResumeFile = () => {
    fetch(localStorage["_file"])
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], localStorage.getItem("_resumefile"), {
          type: "application/json",
        });
        var formData = new FormData();
        formData.append("file", file);
        formData.append("title", localStorage.getItem("_resumefile"));
        ApiCalls.post("candidates/resume", formData).then((response) => {
          localStorage.removeItem("_resumefile");
          localStorage.removeItem("_file");
        });
        (error) => { };
      });
  };
  const storeAlldeatils = () => {
    let obj = JSON.parse(localStorage.getItem("first_step"));
    let obj1 = {
      id: candidateInfo?.id,
      firstName: obj.name.split(" ")[0].trim(),
      lastName: obj.name.split(" ")[1].trim(),
      email: obj.email,
      address: obj.address,
      city: obj.city,
      state: obj.state,
      country: obj.country,
      zipCode: obj.zipCode,
      employerName: obj.employerName,
      profileTitle: obj.profileTitle,
      jobTitle: obj.jobTitle,
      overallYearsOfExperience: obj.overallYearsOfExperience,
    };
    let data = {};
    data.title = obj.jobTitle;
    data.workedWith = obj.employerName;
    data.workedFrom = formatDate(obj.workedFrom);
    data.workedTill = formatDate(obj.Workedto);

    ApiCalls.put("candidates/", obj1).then(
      (response) => {
        ApiCalls.put(
          "candidates/skills",
          JSON.parse(localStorage.getItem("second_step")).filter(
            (skill) => skill.yearsOfExperience
          )
        ).then(
          (response) => {
            localStorage.setItem("third_step", true);
            localStorage.setItem("skillsCount", response.length);
            getProfileInfo();
            ApiCalls.put("candidates/work-history", data)
              .then((response) => {
                getSkills();
                if (localStorage.getItem("_resumefile")) {
                  getResumeFile();
                }
              })
              .catch(function (error) { });
            }).catch(
          (error) => { }
        );
      }).catch(
      (error) => { }
    );
  };
  const getSkills = () => {
    ApiCalls.get("candidates/skills").then(
      (response) => {
        localStorage.setItem("skillsCount", response.length);
        //if(localStorage.getItem("social_login_done") !== "1"){
        localStorage.setItem("social_login_done", 1);
        if (response.length > 0) {
          if (!location.pathname.startsWith("/job/")) {
            if (localStorage.getItem("jobId")) {
              navigate(`../candidate/job/${localStorage.getItem("jobId")}`);
              localStorage.removeItem("registered");
              localStorage.removeItem("jobId");
            } else {
              navigate("../candidate/dashboard");
              // if (location.pathname == "/home") {
              //   navigate("/home");
              // } else {
              //   navigate("../candidate/find-jobs");
              // }
            }
          }
          if (location.pathname.startsWith("/job/")) {
            navigate("../../candidate" + location.pathname);
          }
        } else {
          navigate("../candidate/create-profile");
        }
      }).catch(
      (error) => { }
    );
  };
const MediaIconGrid = () => {
    return (
        <Box className="justify-end align-center display-flex cursor-hover">
        {!candidateInfo?.mediaFileSignedUrl && (
          <Typography
            className="default-img-border justify-center align-center display-flex primary-btn-text bg-input f-16 font-weight-700 text-capitalize candidate-profile"
            width="42px"
            height="42px"
            aria-label="Default image"
          >
            {getValidFullName(candidateInfo.firstName)
              .trim()
              .substring(0, 1)}
          </Typography>
        )}
        {candidateInfo?.mediaFileSignedUrl &&
          (checkIsImage(
            candidateInfo?.mediaFileSignedUrl
          ) ? (
            <img
              width="42px"
              height="42px"
              style={{
                objectFit:
                  candidateInfo?.mediaFileSignedUrl.includes(
                    "gif"
                  )
                    ? "cover"
                    : "",
              }}
              className="round-image default-img-border candidate-profile"
              src={candidateInfo?.mediaFileSignedUrl}
              alt="Defaullt profile"
            />
          ) : (
            <video
              width="42px"
              height="42px"
              className="round-image video-image"
              src={candidateInfo?.mediaFileSignedUrl}
            />
          ))}
      </Box>
    );
};
  return (
    <div ref={myRef}>
      {isMobile ? (
        <>
          <Grid item lg={12} md={12} xs={12} className="">
            <Grid className="navbar-fixed-new header-nav-align galaxy-navbar-padding">
              <Grid>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  md={12}
                  className="justify-between"
                >
                  <Grid item lg={4} md={6} xs={5}>
                    <Box className="cursor-hover">
                      <Link
                        to="/"
                        className="fastpass-issue"
                         aria-label="logo"
                      >
                        <Box className="cursor-hover navLogo p-t-5">
                          {domainNavBarLogoSignedUrl ?
                            <img
                              className="logoImg-header vertical-inherit contrasts pl-35-r"
                              src={(window.location.pathname === "/") ? "https://daas-prog-test.s3.amazonaws.com/app_logo_general.png" : domainNavBarLogoSignedUrl}
                              alt="Logo"
                            />
                            : <img
                              className={`logoImg-header vertical-inherit contrasts pl-35-r`}
                              src={appOrigin === "N" ? Constants.DEFAULT_NEURODIVERSIFY_LOGO_URL : appOrigin === "H" ? Constants.DEFAULT_HEALTHCARE_LOG0_URL : Constants.DEFAULT_GENERAL_LOGO_URL}
                                alt="Logo"
                              />
                          }
                        </Box>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={2}
                    xs={2}
                    className="display-flex align-center margin-right-header"
                  >
                    <>
                      <Button
                        className="m-t-5"
                        aria-controls="full-width-menu"
                        aria-haspopup="true"
                        onClick={handleClick5}
                      >
                        <Grid
                          className="align-center display-flex"
                        >
                          <Typography className="gray9 text-capitalize">
                            <span>{searchedSkills}</span>
                            {searchedSkills && searchedLocation !== ""
                              ? ","
                              : ""}
                            <span className="p-l-5 p-r-5">
                              {searchedLocation}
                            </span>
                          </Typography>
                          <SearchIcon
                            tabIndex={0}
                            className="contrasts focus-issue w-18"
                            aria-label="Search icon"
                            width="18px"
                            height="18px"
                          />
                        </Grid>
                      </Button>
                    </>

                    <Menu
                      id="full-width-menu"
                      className="search-bar-icon"
                      anchorEl={anchorEl5}
                      open={Boolean(anchorEl5)}
                      onClose={handleClose5}
                      keepMounted
                      ref={menuRef}
                      disableScrollLock={true}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      KeepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        style: {
                          width: "100%",
                          maxWidth: "100%",
                          left: "0",
                          right: "0",
                        },
                      }}
                    >
                      <SearchBar
                        anchorEl5={anchorEl5}
                        isAuth={!isAuthenticated}
                        setSearchedSkills={setSearchedSkills}
                        setSearchedLocation={setSearchedLocation}
                        setGlobalSearchClick={setGlobalSearchClick}
                        handleClose5={handleClose5}
                      />
                    </Menu>
                  </Grid>
                  <Grid item lg={2} md={2} xs={1} className="align-item-center">
                    {isloggedIn && (
                      <Grid className="display-flex cursor-hover">
                        <Button
                          id="basic-button-menu"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          className="dropdown-arrow text-transform-none cursor-hover position-issue pxy-8"
                          onClick={handleClick2}
                          role="switch"
                          aria-checked={open ? "true" : "false"}
                          tabIndex={0}
                          disableRipple="true"
                        >
                          {MediaIconGrid()}
                          <ExpandMoreIcon
                            className="f-20 gray9 m-l-5 cursor-hover headerIcon user-logo-align inputcontrasts"
                            aria-label="Dropdown icon"
                          />
                        </Button>

                        <Menu
                          id="account-menu"
                          anchorEl={anchorE2}
                          open={open2}
                          className="cursor-hover notification-hover1"
                          onClose={handleClose2}
                          // onClick={handleClose2}
                          onKeyPress={handleClose2}
                          disableScrollLock={true}
                          MenuListProps={{
                            "aria-labelledby": "basic-button-menu",
                          }}
                          Keepmounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            tabIndex={0}
                            className="border-menu-bottom"
                          >
                            <Button
                              id="basic-button-menu"
                              aria-controls={open ? "account-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              // aria-controls="basic-menu2"
                              className="dropdown-arrow text-transform-none cursor-hover position-issue pxy-8"
                              // onClick={handleClick2}
                              role="switch"
                              aria-checked={open ? "true" : "false"}
                              tabIndex={0}
                              disableRipple="true"
                            >
                              {MediaIconGrid()}
                            </Button>
                            <p className="p-t-2 pr-15">
                              <span className="gray9 text-capitalize">
                                {getValidFullName(
                                  candidateInfo.firstName +
                                  " " +
                                  candidateInfo.lastName
                                )}
                              </span>
                              <br />
                              {candidateInfo.emailAddress}
                            </p>
                          </MenuItem>
                          <MenuItem
                            onClick={goToEditProfile}
                            tabIndex={0}
                            onKeyPress={goToEditProfile}
                            className={`pl-30 my-10 btn ${URLPath === "/candidate/edit-profile"
                              ? "profile-list-btn-active"
                              : "profile-list-btn"
                              }`}
                          >
                            <UserCircle width="24px" icon="user-circle" />
                            <span className="pl-10 p-t-2">My Profile</span>
                          </MenuItem>
                          <MenuItem
                          onClick={goToMyReferrals}
                          tabIndex={0}
                          onKeyPress={goToMyReferrals}
                          onMouseDown={goToMyReferrals}
                          onMouseUp={goToMyReferrals}
                          className={`my-10 btn ${URLPath === "/candidate/my-referrals"
                            ? "profile-list-btn-active"
                            : "profile-list-btn"
                            }`}
                        >
                          <ReferCandidate width="22px" height="22px" icon="refer-candidate" style={{ marginLeft: "3px" }}/>
                          <span className="pl-10 p-t-2">My Referrals</span>
                        </MenuItem>
                          <MenuItem
                            onClick={settingScreen}
                            tabIndex={0}
                            onKeyPress={settingScreen}
                            className={`pl-30 my-10 btn ${URLPath === "/candidate/settings"
                              ? "profile-list-btn-active"
                              : "profile-list-btn"
                              }`}
                          >
                            <SettingsIcon width="24px" />
                            <span className="pl-10 p-t-2">Settings</span>
                          </MenuItem>
                          <MenuItem
                            onClick={goToMessages}
                            aria-label="Message icon focus-issue"
                            role="switch"
                            aria-checked="false"
                            tabIndex={0}
                            disableRipple="true"
                            className={`pl-30 my-10 btn ${URLPath === "/candidate/message-list"
                              ? "profile-list-btn-active"
                              : "profile-list-btn"
                              }`}>
                            <MessageIcon
                              tabIndex={0}
                              className="contrasts focus-issue w-18"
                              aria-label="Message icon"
                            />
                            <span className="pl-10 p-t-2 f-14 gray9 text-transform-none">
                              Messages
                            </span>
                          </MenuItem>
                          <MenuItem className="mb-10 pl-20">
                          <NotificationButton
                            isMobile={isMobile}
                            viewAllNotification={viewAllNotification}/>
                          </MenuItem>
                          <MenuItem
                            className="pl-30 my-10 btn"
                            onClick={handleOpenFeedBack}
                            aria-label="Message icon focus-issue"
                            role="switch"
                            aria-checked="false"
                            tabIndex={0}
                            disableRipple="true">
                            <SupportIcon
                              tabIndex={0}
                              className="contrasts focus-issue w-18"
                              aria-label="Feedback icon"
                            />
                            <span className="pl-10 p-t-2 f-14 gray9 text-transform-none">
                              Feedback
                            </span>
                          </MenuItem>
                          <MenuItem
                            className="danger-color cursor-hover pl-25 pt-10 border-menu-top"
                            onClick={signOut}
                            onKeyPress={signOut}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="danger-color"
                              width="24px"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                              />
                            </svg>
                            <span className="pl-10 p-t-2">Logout</span>
                          </MenuItem>
                        </Menu>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={2}
                    xs={2}
                    className="align-item-center "
                  >
                    <Box className="align-center display-flex">
                      <Button
                        variant="text"
                        onClick={handleMenu}
                        className="justify-end"
                        role="switch"
                        tabIndex={0}
                        disableRipple="true"
                      >
                        {" "}
                        <MenuIcon
                          className="gray9 f-25"
                          aria-label="Menu icon"
                        />
                      </Button>
                    </Box>
                    <Menu
                      className="navbar-responsive sidebar-dropdown"
                      id="menu-appbar"
                      disableScrollLock={true}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      Keepmounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={open}
                    >
                      <Grid
                        container
                        item
                        lg={6}
                        md={6}
                        className="justify-end align-center navbar-responsive"
                        sx={{ mt: 7 }}
                      >
                        <Box>
                          <Box className="align-right">
                            <CloseIcon
                              className="f-25 font-weight-600 p-3px cursor-hover sub-text-color"
                              onClick={handleCloseMenu}
                              aria-label="Close icon"
                              tabIndex={0}
                              onKeyPress={handleCloseMenu}
                            />
                          </Box>
                          {isloggedIn ? (
                            <>
                            {CandidateMenu.map((menu) => {
                            return (
                              menu.name == "Reports" ?
                                <MenuItem className="mt-10">
                                    <AnalyticsSideBar handleCloseMenu={handleCloseMenu} /></MenuItem> : (menu.name == "Assessments" && appOrigin !== "N") ?
                                  <MenuItem>
                                  <Button
                                    id="basic-button-menu"
                                    aria-controls={
                                      open ? "account-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    // aria-controls="basic-menu2"
                                    //className="dropdown-arrow text-transform-none cursor-hover"
                                    style={{ padding: "6px 8px", justifyContent: "left" }}
                                    onClick={handleClick4}
                                    role="switch"
                                    aria-checked={open ? "true" : "false"}
                                    tabIndex={0}
                                    disableRipple="true"
                                    className={
                                      URLPath === "/candidate/add-checklist"
                                        ? "dropdown-arrow text-transform-none cursor-hover btn activebutton-header  infoFont profile-btn"
                                        : "btn button-header  infoFont profile-btn"
                                    }
                                  >
                                    Assessments
                                    <ExpandMoreIcon
                                      className="cursor-hover headerIcon inputcontrasts"
                                      aria-label="Dropdown icon"
                                    />
                                  </Button>
                                  <Menu
                                    style={{ top: "5px" }}
                                    id="account-menu"
                                    anchorEl={anchorE4}
                                    open={open4}
                                    className="cursor-hover"
                                    onClose={handleClose4}
                                    onClick={handleClose4}
                                    disableScrollLock={true}
                                    onKeyPress={handleClose4}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button-menu",
                                    }}
                                    Keepmounted
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "right",
                                      vertical: "bottom",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={goToChecklist}
                                      onKeyPress={goToChecklist}
                                      style={{ padding: "0 10px" }}
                                    >
                                      <Typography className="display-flex">
                                        <Typography className="p-t-2 px-15">
                                          Checklist
                                        </Typography>
                                      </Typography>
                                    </MenuItem>
                                  </Menu>
                                </MenuItem> : <MenuItem className="mt-10"><Button
                                    className={
                                      window.location.href.toLowerCase().indexOf(window.location.host + menu.url.toLowerCase()) !== -1
                                        ? "btn activebutton-header infoFont profile-btn"
                                        : "btn button-header  infoFont profile-btn"
                                    }
                                    onClick={() => navigate(menu.url)}
                                    tabIndex={0}
                                    disableRipple="true"
                                    style={{ justifyContent: "left" }}
                                  >
                                    {menu.name}
                                  </Button></MenuItem>
                            );
                          })}
                            </>
                          ) : (
                            <>
                              <Box sx={{ px: 4 }} style={{ paddingTop: "4px" }}>
                                <Button
                                  className={`${window.location.pathname === "/" ? "home-page-link-color" : "link-color"} small-text-font cursor-hover display-flex text-decoration-none font-weight-600 text-capitalize text-capitalize font-family`}
                                  onClick={goToSignin}
                                >
                                  Sign In
                                </Button>
                              </Box>
                              <Box sx={{ px: 4 }} style={{ paddingTop: "4px" }}>
                                <Button
                                  className={`${window.location.pathname === "/" ? "home-page-link-color" : "link-color"} small-text-font cursor-hover display-flex text-decoration-none font-weight-600 job-detail-align text-capitalize font-family`}
                                  onClick={goToSignup}
                                >
                                  Sign Up
                                </Button>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              <SearchBar
                show={show}
                isAuth={!isAuthenticated}
                setShow={setShow}
                setSearchedSkills={setSearchedSkills}
                setSearchedLocation={setSearchedLocation}
                setGlobalSearchClick={setGlobalSearchClick}
              />
            </Grid>
            <Grid></Grid>
          </Grid>
        </>
      ) : (
        <Grid className="navbar-fixed-new padding-new-navbar">
          {isLoading && <Loader />}
          <Grid
            container
            spacing={1}
            className="body-container accessibility-container"
            // style={{ padding: "0px 36px" }}
            style={{ margin: "0px auto" }}
            // className="body-container"
            sx={{ pl: 4, pr: 5 }}
          >
            <Grid
              container
              item
              lg={`${appOrigin != "N" ? "2" : "2.7"}`}
              md={2}
              xs={2}
              spacing={1}
              className="align-center p-b-5 pt-15"
            >
              {isloggedIn ? (
                <>
                  <Link to="/candidate/dashboard" className="fastpass-issue">
                    <Box className="cursor-hover navLogo align-item-center ">
                      {domainNavBarLogoSignedUrl ?
                          <img
                              className="logoImg-header vertical-inherit contrasts pl-35-r"
                              src={domainNavBarLogoSignedUrl}
                              alt="Logo"
                          />
                          :
                          <img
                              className={`logoImg-header vertical-inherit contrasts ${appOrigin === "N" ? "mb-10" : ""}`}
                              src={appOrigin === "N" ? "https://daas-prog-test.s3.amazonaws.com/app_logo_neurodiversify.png" : appOrigin === "H" ? "https://daas-prog-test.s3.amazonaws.com/app_logo_healthcare.png" : "https://daas-prog-test.s3.amazonaws.com/app_logo_general.png"}
                              alt="Logo"
                          />
                      }
                    </Box>
                  </Link>
                </>
              ) : (
                firstLoad ? (
                  <Grid style={{ width: "70%" }}>
                    <Typography className="display-flex justify-between">
                      <div className="loader-in-header">
                        <div className="">
                          <div className="shimmerBG content-line end"></div>
                          <div className="shimmerBG title-line m-b-0"></div>
                        </div>
                      </div>
                    </Typography>
                  </Grid>
                ) :
                <>
                  {domainNavBarLogoSignedUrl ?
                    <Link to="/" className="fastpass-issue">
                      <Box className="cursor-hover navLogo align-item-center pt-1">
                      <img
                          className="logoImg-header vertical-inherit contrasts pl-35-r"
                          src={(window.location.pathname === "/") ? "https://daas-prog-test.s3.amazonaws.com/app_logo_general.png" : domainNavBarLogoSignedUrl}
                          alt="Logo"
                        />
                      </Box>
                    </Link>
                    : ""}
                  {!domainNavBarLogoSignedUrl && appOrigin === "D" &&
                    <Link to="/" className="fastpass-issue">
                      <Box className="cursor-hover navLogo align-item-center pt-1">
                        <img
                          className="logoImg-header vertical-inherit contrasts"
                          src={Constants.DEFAULT_GENERAL_LOGO_URL}
                          alt="Logo"
                        />
                      </Box>
                    </Link>
                  }
                  {!domainNavBarLogoSignedUrl && appOrigin === "H" &&
                    <Link to="/" className="fastpass-issue">
                      <Box className="cursor-hover navLogo align-item-center pt-1">
                        <img
                          className="logoImg-header vertical-inherit contrasts"
                          src={Constants.DEFAULT_HEALTHCARE_LOG0_URL}
                          alt="Logo"
                        />
                      </Box>
                    </Link>
                  }
                  {!domainNavBarLogoSignedUrl && appOrigin === "N" &&
                    <a to={process.env.REACT_APP_LAUNCH_PAGE_BASE_URL} className="fastpass-issue">
                      <Box className="cursor-hover navLogo align-item-center pt-1">
                        <img
                          className="logoImg-header vertical-inherit contrasts"
                          src={Constants.DEFAULT_NEURODIVERSIFY_LOGO_URL}
                          alt="Logo"
                        />
                      </Box>
                    </a>
                  }
                </>
              )}
            </Grid>

            <Grid
              container
              item
              lg={`${appOrigin != "N" ? "6" : "5.3"}`}
              md={6}
              xs={6}
              className="p-0 p-l-0 justify-between align-center active-button-list"
            >
              {isloggedIn && (
                <>
                 {CandidateMenu.map((menu) => {
                return (
                    menu.name == "Reports" ? <AnalyticsSideBar /> : (menu.name == "Assessments" && appOrigin !== "N") ?
                      <Grid className="display-flex justify-end align-center">
                        <>
                        <Button
                        id="basic-button-menu"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        // aria-controls="basic-menu2"
                        //className="dropdown-arrow text-transform-none cursor-hover"
                        style={{ padding: "6px 8px" }}
                        onClick={handleClick4}
                        role="switch"
                        aria-checked={open ? "true" : "false"}
                        tabIndex={0}
                        disableRipple="true"
                        className={
                          URLPath === "/candidate/add-checklist"
                            ? "dropdown-arrow text-transform-none cursor-hover btn activebutton-header  infoFont profile-btn"
                            : "btn button-header  infoFont profile-btn"
                        }
                      >
                        Assessments
                        <ExpandMoreIcon
                          className="cursor-hover headerIcon inputcontrasts"
                          aria-label="Dropdown icon"
                        />
                      </Button>
                      <Menu
                        style={{ top: "5px" }}
                        id="account-menu"
                        anchorEl={anchorE4}
                        open={open4}
                        className="cursor-hover"
                        onClose={handleClose4}
                        onClick={handleClose4}
                        disableScrollLock={true}
                        onKeyPress={handleClose4}
                        MenuListProps={{
                          "aria-labelledby": "basic-button-menu",
                        }}
                        Keepmounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={goToChecklist}
                          onKeyPress={goToChecklist}
                          style={{ padding: "0 10px" }}
                        >
                          <Typography className="display-flex">
                            <Typography className="p-t-2 px-15">
                              Checklist
                            </Typography>
                            {/* {candidateDraftChecklistsLength !== 0 && candidateDraftChecklistsLength !== null &&
                          <Typography className="d-content">
                            <span className="draft-count m-b-0 "
                              style={{ height: "20px", width: '20px' }}
                            >{candidateDraftChecklistsLength}</span>
                          </Typography>
                        } */}
                          </Typography>
                        </MenuItem>
                      </Menu>
                        </>
                      </Grid> : <Button
                        className={
                          window.location.href.toLowerCase().indexOf(window.location.host + menu.url.toLowerCase()) !== -1
                            ? "btn activebutton-header infoFont profile-btn"
                            : "btn button-header  infoFont profile-btn"
                        }
                        onClick={() => {
                          navigate(menu.url);
                          getPendingRatings();
                        }}
                        tabIndex={0}
                        disableRipple="true"
                      >
                        {menu.name}
                      </Button>
                );
              })}
                </>
              )}
            </Grid>
            <Grid
              container
              item
              lg={`${appOrigin != "N" ? "4" : "4"}`}
              md={4}
              xs={4}
              className="align-center justify-end p-t-0 nav-menu"
            >
              <>
                {appOrigin != "N" &&
                  <Button
                    aria-controls="full-width-menu"
                    aria-haspopup="true"
                    onClick={handleClick5}
                    className="ipad-search-align nav-menu"
                  >
                    <Grid
                      className="align-center display-flex"
                    >
                      <Typography className="gray9 text-capitalize">
                        <span>{searchedSkills}</span>
                        {searchedSkills && searchedLocation !== "" ? "," : ""}
                        <span className="p-l-5 p-r-5">{searchedLocation}</span>
                      </Typography>
                      <SearchIcon
                        tabIndex={0}
                        className="contrasts focus-issue w-18"
                        aria-label="Search icon"
                        width="18px"
                        height="18px"
                      />
                    </Grid>
                  </Button>
                }
              </>
              <Menu
                id="full-width-menu"
                className="search-bar-icon"
                anchorEl={anchorEl5}
                open={Boolean(anchorEl5)}
                onClose={handleClose5}
                keepMounted
                ref={menuRef}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: "100%",
                    maxWidth: "100%",
                    left: "0",
                    right: "0",
                  },
                }}
              >
                <SearchBar
                  anchorEl5={anchorEl5}
                  isAuth={!isAuthenticated}
                  setSearchedSkills={setSearchedSkills}
                  setSearchedLocation={setSearchedLocation}
                  setGlobalSearchClick={setGlobalSearchClick}
                  handleClose5={handleClose5}
                />
              </Menu>

              {isloggedIn ? (
                <>
                  <Button
                    style={{ minWidth: "50px" }}
                    className="cursor-hover display-flex pxy-8"
                    onClick={goToMessages}
                    onKeyPress={goToMessages}
                    tabIndex={0}
                  >
                    <Tooltip title="Messages" placement="bottom">
                      <MessageIcon
                        style={{ width: "18px" }}
                        aria-label="Message icon"
                      />
                    </Tooltip>
                  </Button>
                  <NotificationButton
                  isMobile={isMobile}
                  viewAllNotification={viewAllNotification}/>
                  <Button
                    style={{ minWidth: "50px" }}
                    className="cursor-hover display-flex pxy-8"
                    onClick={handleOpenFeedBack}
                    onKeyPress={handleOpenFeedBack}
                    tabIndex={0}
                  >
                    <Tooltip title="Feedback" placement="bottom">
                      <SupportIcon
                        style={{ width: "20px" }}
                        aria-label="Message icon"
                      />
                    </Tooltip>
                  </Button>
                  <Grid className="display-flex align-center">
                    <Box className="my-10" style={{ display: "none" }}>
                      <Button
                        className="btn secondary-button small-text-font infoFont profile-btn"
                        onClick={switchToEnterprise}
                        disableRipple="true"
                      >
                        Switch to Enterprise
                      </Button>
                    </Box>
                  </Grid>
                  <Grid className="display-flex cursor-hover">
                    <Button
                      id="basic-button2"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-expanded={open ? "true" : "false"}
                      aria-haspopup="true"
                      className="dropdown-arrow text-transform-none cursor-hover"
                      onClick={handleClick2}
                      disableRipple="true"
                    >
                      {MediaIconGrid()}
                      <Box sx={{ pl: 1 }} className="my-10 display-flex">
                        <Tooltip
                          title={
                            <div>
                              {getValidFullName(
                                candidateInfo.firstName +
                                " " +
                                candidateInfo.lastName
                              )}{" "}
                              <br />
                              {candidateInfo?.profileTitle} <br />
                              {candidateInfo?.emailAddress}
                            </div>
                          }
                          placement="top"
                        >
                          <Typography
                            // variant="h5"
                            className="gray9 f-14 font-weight-500 cursor-hover textEllipse candidate-name-width align-left w-100px"
                          >
                            {getValidFullName(
                              candidateInfo?.firstName + " " + candidateInfo?.lastName
                            )}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <ExpandMoreIcon
                        className="f-20 gray9 ml-10 inputcontrasts"
                        aria-label="Dropdown icon"
                      />
                    </Button>
                    <Menu
                      id="basic-menu2"
                      anchorEl={anchorE2}
                      open={open2}
                      onClose={handleClose2}
                      onClick={handleClose2}
                      onKeyPress={handleClose2}
                      className="cursor-hover"
                      disableScrollLock={true}
                      MenuListProps={{
                        "aria-labelledby": "basic-button2",
                      }}
                      Keepmounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        tabIndex={0}
                        className="border-menu-bottom"
                      >
                        <Button
                          id="basic-button-menu"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // aria-controls="basic-menu2"
                          className="dropdown-arrow text-transform-none cursor-hover position-issue pxy-8"
                          // onClick={handleClick2}
                          role="switch"
                          aria-checked={open ? "true" : "false"}
                          tabIndex={0}
                          disableRipple="true"
                        >
                          {MediaIconGrid()}
                        </Button>
                        <p className="p-t-2 pr-15">
                          <span className="gray9 text-capitalize">
                            {getValidFullName(
                              candidateInfo.firstName +
                              " " +
                              candidateInfo.lastName
                            )}
                          </span>
                          <br />
                          {candidateInfo.emailAddress}
                        </p>
                      </MenuItem>
                      <MenuItem
                        onClick={goToEditProfile}
                        tabIndex={0}
                        onKeyPress={goToEditProfile}
                        // className="pl-30 mt-10 btn profile-list-btn"
                        className={`my-10 btn ${URLPath === "/candidate/edit-profile"
                          ? "profile-list-btn-active"
                          : "profile-list-btn"
                          }`}
                      >
                        <UserCircle width="24px" icon="user-circle" />
                        <span className="pl-10 p-t-2">My Profile</span>
                      </MenuItem>
                      <MenuItem
                          onClick={goToMyReferrals}
                          tabIndex={0}
                          onKeyPress={goToMyReferrals}
                          onMouseDown={goToMyReferrals}
                          onMouseUp={goToMyReferrals}
                          className={`my-10 btn ${URLPath === "/candidate/my-referrals"
                            ? "profile-list-btn-active"
                            : "profile-list-btn"
                            }`}
                        >
                          <ReferCandidate width="22px" height="22px" icon="refer-candidate" style={{ marginLeft: "3px" }}/>
                          <span className="pl-10 p-t-2">My Referrals</span>
                        </MenuItem>
                      <MenuItem
                        onClick={settingScreen}
                        tabIndex={0}
                        onKeyPress={settingScreen}
                        className={`my-10 btn ${URLPath === "/candidate/settings"
                          ? "profile-list-btn-active"
                          : "profile-list-btn"
                          }`}
                      >
                        <SettingsIcon width="24px" />
                        <span className="pl-10 p-t-2">Settings</span>
                      </MenuItem>

                      <MenuItem
                        className="danger-color cursor-hover m-l-5 pt-10 border-menu-top"
                        onClick={signOut}
                        onKeyPress={signOut}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="danger-color"
                          width="24px"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                          />
                        </svg>
                        <span className="pl-10 p-t-2">Logout</span>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </>
              ) : (
                <>
                  {!params.get("isRedirectBy") && (
                    <>
                      <Box
                        sx={
                          searchedSkills && searchedLocation
                            ? { px: 1 }
                            : searchedSkills || searchedLocation
                            ? { px: 2 }
                            : { px: 4 }
                        }
                        // style={{ paddingTop: "4px" }}
                      >
                        <Button
                          className={`${window.location.pathname === "/" ? "home-page-link-color" : "link-color"} small-text-font cursor-hover display-flex text-decoration-none font-weight-600 text-capitalize font-family`}
                          onClick={goToSignin}
                        >
                          Sign In
                        </Button>
                      </Box>
                      <Box
                      // style={{ paddingTop: "4px" }}
                      >
                        {/*  <Button
                      className="btn primary-button"
                      style={{ padding: "4px 16px" }}
                    >*/}
                        <Button
                          className={`${window.location.pathname === "/" ? "home-page-link-color" : "link-color"} small-text-font cursor-hover display-flex text-decoration-none font-weight-600 text-capitalize font-family`}
                          onClick={goToSignup}
                        >
                          Sign Up
                        </Button>
                        {/* </Button>*/}
                      </Box>{" "}
                    </>
                  )}
                  {params.get("isRedirectBy") && (
                    <>
                      <Box sx={{ px: 4 }} style={{ paddingTop: "4px" }}>
                        <a
                          className={`${window.location.pathname === "/" ? "home-page-link-color" : "link-color"} small-text-font cursor-hover display-flex text-decoration-none font-weight-600`}
                          onClick={() => navigateToEnterprise()}
                        >
                          Sign In
                        </a>
                      </Box>
                      <Box style={{ paddingTop: "4px" }}>
                        <a
                          className={`${window.location.pathname === "/" ? "home-page-link-color" : "link-color"} small-text-font cursor-hover display-flex text-decoration-none font-weight-600`}
                          onClick={() => navigateToEnterprise()}
                        >
                          Sign Up
                        </a>
                      </Box>{" "}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid>
            <SearchBar
              show={show}
              isAuth={!isAuthenticated}
              setShow={setShow}
              setSearchedSkills={setSearchedSkills}
              setSearchedLocation={setSearchedLocation}
              setGlobalSearchClick={setGlobalSearchClick}
            />
          </Grid>
        </Grid>
      )}
      <FeedBackModal open={openfeedback} onClose={handleModalClose} />
      <Modal
        open={openValidUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle inputcontrasts">
          <Typography className="align-left">
            <Typography className="f-14 font-weight-400 gray9">
              {validUserContent}
            </Typography>
            <Typography className="align-right mt-20">
              <Button
                className="btn secondary-button mr-10"
                onClick={() => signOutUser()}
                disableRipple="true"
              >
                No
              </Button>
              <Button
                className="btn primary-button"
                onClick={() => convertToValidUser()}
                disableRipple="true"
              >
                Yes
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
